<template>
  <div class="btn-halloween" @click="goToHalloweenGame">
    <div class="pumpkin scary">
      <div class="body">
         <span></span>
         <span></span>
         <span></span>
         <span></span>
      </div>
      <div class="face">
         <span class="eye"></span>
         <span class="eye"></span>
         <span class="mouth"></span>
      </div>
      <div class="tale"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonToHalloween',
  methods: {
    goToHalloweenGame() {
      const audio = new Audio('/media/audio/halloween/scream.mp3')
      audio.play();
      setTimeout(() => {
        this.$router.push({ name: 'Halloween' })
      }, 1100)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-halloween {
  display: grid;
  place-items: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: fit-content;
  cursor: pointer;
  transform: scale(.75);
}

// The Pumpkin!
   .pumpkin {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      // Some shadows
      &:before,
      &:after {
         width: 80%;
         height: 8%;
         position: absolute;
         background-color: black;
         content: "";
         z-index: -1;
         border-radius: 100%;
         box-shadow: 0px 0px 20px 20px black;
         bottom: 15px;
         opacity: 0.2;
      }
      &:after {
         width: 60%;
         height: 8%;
         box-shadow: 0px 0px 10px 20px black;
         opacity: 0.5;
         bottom: 20px;
      }

      // the orange body!
      .body {
         width: 230px;
         height: 150px;
         display: flex;
         align-items: center;
         justify-content: center;

         span {
            height: 150px;
            background-color: orange;
            display: flex;
            position: absolute;
            border-radius: 100px;
            box-shadow: inset -10px 20px 30px 0px rgba(white, 0.3),
               inset 0px 2px 5px 0px rgba(white, 0.2),
               inset 0px -10px 10px -15px rgba(black, 1),
               inset 3px -20px 10px -10px rgba(#69382c, 0.5),
               inset 20px -20px 20px #e98829;

            &:nth-of-type(1) {
               width: 150px;
               left: 0px;
               bottom: 5px;
            }
            &:nth-of-type(2) {
               width: 170px;
               z-index: 1;
            }
            &:nth-of-type(3) {
               width: 80px;
               z-index: 1;
               border-radius: 100%;
            }
            &:nth-of-type(4) {
               width: 150px;
               right: 0px;
               bottom: 5px;
            }
         }
      }
      
      // face
      .face {
         position: absolute;
         display: flex;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 100%;

         // the eyes
         .eye {
            z-index: 2;
            display: flex;
            position: absolute;
            width: 18px;
            height: 25px;
            background-color: #69382c;
            border-radius: 20px;
            transition: all 0.2s ease;
            box-shadow: inset 6px -2px 0px 0px rgba(orange, 0.4),
               inset -2px 4px 10px 0px rgba(black, 0.3),
               inset 2px -4px 10px 0px rgba(black, 0.2),
               2px -2px 5px 0px rgba(white, 0.3);

            top: 40px;

            &:nth-of-type(1) {
               left: 90px;
            }
            &:nth-of-type(2) {
               right: 90px;
            }
         }

         // the mouth
         .mouth {
            position: absolute;
            width: 80px;
            height: 25px;
            background-color: #69382c;
            display: flex;
            z-index: 3;
            bottom: 35px;
            transition: all 0.2s ease;
            border-bottom-left-radius: 300px;
            border-bottom-right-radius: 300px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-shadow: inset 4px -6px 0px 0px rgba(orange, 0.4),
               inset -2px 4px 10px 0px rgba(black, 0.3),
               inset 2px -4px 10px 0px rgba(black, 0.2),
               2px -2px 5px 0px rgba(orange, 0.8);

            &:before,
            &:after {
               width: 15px;
               height: 10px;
               position: absolute;
               background-color: orange;
               content: "";
            }
            &:before {
               left: 20px;
               box-shadow: inset -4px 0px 0px 0px rgba(#69382c, 0.8);
               border-radius: 0px 0px 2px 2px;
            }

            &:after {
               bottom: 0;
               right: 18px;
               height: 12px;
               border-radius: 2px 2px 0px 0px;
               box-shadow: inset -4px 0px 0px 0px rgba(#69382c, 0.8);
            }
         }
      }

      // the green tale!
      .tale {
         position: absolute;
         width: 15px;
         height: 30px;
         background: linear-gradient(180deg, #4e6c3b 40%, #3f5532 70%);
         display: flex;
         z-index: -1;
         top: -35px;
         transition: all 0.2s ease;
         border-radius: 5px;
         transform: rotate(10deg);
         box-shadow: inset 0px 14px 8px -10px rgba(white, 0.3);

         &:before,
         &:after {
            width: 16px;
            height: 40px;
            position: absolute;
            background-color: #3f5532;
            content: "";
            left: -10px;
            transform: rotate(30deg);
            top: 20px;
         }

         &:after {
            transform: rotate(-80deg);
            left: 10px;
            top: 14px;
         }
      }

      // When it gets scary!! 🎃
      &.scary {
         .body {
            position: relative;

            // The red glow
            &:before {
               width: 90%;
               height: 90%;
               position: absolute;
               background-color: red;
               content: "";
               z-index: -1;
               border-radius: 100%;
               box-shadow: 0px 0px 60px red;
               animation: glow 1s ease infinite alternate;
            }

            @keyframes glow {
               100% {
                  width: 80%;
                  height: 80%;
                  box-shadow: 0px 0px 100px 4px red;
               }
            }
         }
         .face {
            .eye {
               width: 20px;
               height: 10px;
               top: 45px;
               background-color: red;
               box-shadow: inset 6px -2px 0px 0px rgba(black, 0.15),
                  inset -2px 4px 10px 0px rgba(black, 0.3),
                  inset 2px -4px 10px 0px rgba(black, 0.2);

               &:nth-of-type(1) {
                  left: 85px;
               }
               &:nth-of-type(2) {
                  right: 85px;
               }

               &:before {
                  width: 100%;
                  height: 100%;
                  content: "";
                  position: absolute;
                  background-color: transparent;
                  border-radius: inherit;
                  z-index: -1;
                  box-shadow: 0px 0px 10px red;
                  animation: eyes 1s ease infinite alternate;
               }

               @keyframes eyes {
                  100% {
                     box-shadow: 0px 0px 20px 2px red;
                  }
               }
            }
            .mouth {
               bottom: 40px;
               border-bottom-left-radius: 30px;
               border-bottom-right-radius: 30px;
               border-top-left-radius: 100px;
               border-top-right-radius: 100px;
               background-color: red;
               box-shadow: inset 4px -6px 0px 0px rgba(black, 0.15),
                  inset -2px 4px 10px 0px rgba(black, 0.3),
                  inset 2px -4px 10px 0px rgba(black, 0.2),
                  2px -2px 30px 0px rgba(red, 1);

               &:before {
                  box-shadow: inset -4px 0px 0px 0px rgba(#990404, 0.8),
                     inset 0px 3px 20px 0px rgba(red, 0.6);
               }

               &:after {
                  box-shadow: inset -4px 0px 0px 0px rgba(#990404, 0.8),
                     inset 0px 2px 10px 0px rgba(red, 0.8);
               }
            }
         }
      }
   }
</style>
